<template>
    <div class="grid grid-cols-12">
        <!-- NGO name -->
        <div class="flex col-span-6 md:col-span-8 h1 ml-30 mb-22 mt-30 pl-20">
            <p
                v-if="userTypeManager"
                class="text-4xl text-gray-800 opacity-85 font-bold font-titillium-bold"
            >{{ intialUserData.ngo.name }}</p>
            <p
                v-else
                class="text-4xl text-gray-800 opacity-85 font-bold font-titillium-bold"
            >{{ initialStaffUser.ngo_name }}</p>
        </div>
        <!-- Logout button -->
        <div class="col-span-6 md:col-span-4 flex justify-end items-center mr-30 relative">
            <Button
                @btnClick="onLogout"
                class="button bg-gray-500 hover:bg-gray-600 text-white px-10 md:px-0 w-auto md:w-257 cursor-pointer"
                role="submit"
                :text="$t('profileLogoutButtonText')"
                :disabled="logoutLoading"
                :spinning="logoutLoading"
            />
            <!-- Confirmation box for success messages -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <!-- Ong description -->
        <div
            v-if="userTypeManager"
            class="col-span-12 ml-30 mb-30 pl-20 mr-30 tracking-tight text-gray-800 small"
        >
            {{ intialUserData.ngo.description }}
        </div>
        <!-- Ngo details -->
        <div
            v-if="userTypeManager"
            class="col-span-12 bg-white mx-30 mb-30 rounded-lg p-20 shadow"
        >
            <!-- Detalii despre organizatie -->
            <form
                class="grid grid-cols-12 gap-x-30"
                @input="activateSaveNgoButton"
                @submit.prevent="onUserNgoUpdate"
            >
                <Title
                    class="col-span-12 md:col-span-7"
                    :title="$t('profileOrganizationDetailsTitle')"
                    :subtitle="$t('profileOrganizationDetailsSubtitle')"
                />
                <div class="col-span-12 md:col-span-5 flex justify-start mb-30 md:mb-0 md:justify-end">
                    <Button
                        class="button w-150 bg-purple-500 text-white hover:bg-purple-600 cursor-pointer"
                        role="submit"
                        :disabled="disabledSaveOrganization || saveNgoDetailsLoading"
                        :text="$t('profileSaveButtonText')"
                        profileBtn="true"
                        :spinning="saveNgoDetailsLoading"
                    />
                </div>
                <hr class="col-span-12 mb-30">
                <div class="col-span-12 lg:col-span-4">
                    <!-- Services field -->
                    <div class="mb-20">
                        <label class="flex">{{$t('profileServicesLabel')}}</label>
                        <Multiselect
                            class="rounded border"
                            :class="[errors['services.0.id'] ? 'border-red-500' : 'border-gray-200']"
                            @input="servicesSelected"
                            v-model="user.ngo.services"
                            :options="services"
                            :multiple="true"
                            label="name"
                            track-by="name"
                            :placeholder="$t('servicesFieldPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['services.0.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['services.0.id'][0]}}
                        </p>
                    </div>
                    <!-- Webiste field -->
                    <Input
                        v-model="user.ngo.website"
                        :error="errors.website"
                        class="mb-20"
                        :label="$t('profileWebsiteLabel')"
                        :placeholder="$t('profileWebsitePlaceholder')"
                        type="text"
                        name="website"
                    />
                    <!-- CUI field -->
                    <Input
                        v-model="user.ngo.cif"
                        :error="errors.cif"
                        class="mb-20"
                        :label="$t('profileCuiLabel')"
                        :placeholder="$t('profileCuiPlaceholder')"
                        type="text"
                        name="cui/cif"
                    />
                </div>
                <div class="col-span-12 lg:col-span-8 mb-40">
                    <!-- Description field -->
                    <Textarea
                        v-model="user.ngo.description"
                        :error="errors.description"
                        class="mb-20"
                        :label="$t('profileDescriptionLabel')"
                        name="description"
                        :placeholder="$t('profileDescriptionPlaceholder')"
                    />
                    <!-- Registration number field -->
                    <Input
                        v-model="user.ngo.registration_number"
                        :error="errors.registration_number"
                        :label="$t('profileNrRegistruNationalLabel')"
                        :placeholder="$t('profileNrRegistruNationalPlaceholder')"
                        type="text"
                        name="regNumber"
                    />
                </div>
                <div class="col-span-12">
                    <p class="text-lg font-bold opacity-85 tracking-tight text-gray-800 font-titillium-bold mb-20">{{$t('profileAddressTitle')}}</p>
                </div>
                <!-- County field -->
                <div class="col-span-12 mb-20 lg:col-span-4 lg:mb-0">
                    <label class="flex">{{$t('profileAddressCountyLabel')}}</label>
                    <Multiselect
                        class="rounded border"
                        :class="[errors.county_id ? 'border-red-500' : 'border-gray-200']"
                        @input="countySelected"
                        v-model="user.ngo.county"
                        label="name"
                        track-by="id"
                        :options="counties"
                        :slected="user.ngo.county"
                        :placeholder="$t('profileAddressCountyPlaceholder')"
                        :deselectLabel="$t('selectRemoveOptionText')"
                        :selectLabel="$t('selectOptionText')"
                        :selectedLabel="$t('selectOptionSelectedText')"
                    >
                        <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                        <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                    </Multiselect>
                    <p
                        v-if="errors.county_id"
                        class="text-red-500 small mt-4"
                    >
                        {{errors.county_id[0]}}
                    </p>
                </div>
                <!-- City field -->
                <div class="col-span-12 mb-20 lg:col-span-4 lg:mb-0">
                    <label class="flex">{{$t('profileAddressCityLabel')}}</label>
                    <Multiselect
                        class="rounded border"
                        :class="[errors.city_id ? 'border-red-500' : 'border-gray-200']"
                        @input="citySelected"
                        v-model="user.ngo.city"
                        label="name"
                        track-by="id"
                        :options="cities"
                        :placeholder="$t('profileAddressCityPlaceholder')"
                        :deselectLabel="$t('selectRemoveOptionText')"
                        :selectLabel="$t('selectOptionText')"
                        :selectedLabel="$t('selectOptionSelectedText')"
                    >
                        <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                        <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                    </Multiselect>
                    <p
                        v-if="errors.city_id"
                        class="text-red-500 small mt-4"
                    >
                        {{errors.city_id[0]}}
                    </p>
                </div>
                <!-- Street field -->
                <Input
                    v-model="user.ngo.address"
                    :error="errors.address"
                    class="col-span-12 lg:col-span-4"
                    :label="$t('profileAddressAddressLabel')"
                    :placeholder="$t('profileAddressAddressPlaceholder')"
                    type="text"
                    name="address"
                />
            </form>
        </div>
        <!-- User details -->
        <div class="col-span-12 bg-white mx-30 mb-30 rounded-lg p-20 shadow">
            <form
                class="grid grid-cols-12 gap-x-30"
                @submit.prevent="onUserProfileUpdate"
                @input="activateSaveUserProfile"
            >
                <Title
                    class="col-span-12 md:col-span-6"
                    :title="$t('profileAccountDetailsTitle')"
                    :subtitle="$t('profileAccountDetailsSubtitle')"
                />
                <div class="col-span-12 md:col-span-6 flex justify-start md:justify-end mb-30 md:mb-0">
                    <Button
                        class="button w-150 mr-22 bg-purple-500 text-white hover:bg-purple-600 cursor-pointer"
                        role="submit"
                        :disabled="disabledSaveAboutYou || saveProfileDetailsLoading"
                        :text="$t('profileSaveButtonText')"
                        profileBtn="true"
                        :spinning="saveProfileDetailsLoading"
                    />
                    <Button
                        @btnClick="showModal = true, eraseErrors()"
                        role="button"
                        class="button w-257 text-white bg-gray-500 hover:bg-gray-600 cursor-pointer"
                        :text="$t('profilePasswordResetButtonText')"
                    />
                </div>
                <hr class="col-span-12 mb-30">
                <!-- User name filed -->
                <Input
                    v-model="user.name"
                    :error="errors.name"
                    :class="[errors.name ? 'mb-4' : 'mb-22']"
                    class="col-span-12 mb-20 lg:col-span-4 lg:mb-0"
                    :label="$t('profileNameLabel')"
                    :placeholder="$t('profileNamePlaceholder')"
                    type="text"
                    name="name"
                />
                <!-- User email filed -->
                <Input
                    v-model="user.email"
                    class="col-span-12 mb-20 lg:col-span-4 lg:mb-0"
                    :error="errors.email"
                    :class="[errors.email ? 'mb-4' : 'mb-22']"
                    :label="$t('profileEmailLabel')"
                    :placeholder="$t('profileEmailPlaceholder')"
                    type="email"
                    name="email"
                />
                <!-- User phone filed -->
                <Input
                    v-model="user.phone"
                    :error="errors.phone"
                    :class="[errors.phone ? 'mb-4' : 'mb-22']"
                    class="col-span-12 lg:col-span-4"
                    :label="$t('profilePhoneLabel')"
                    :placeholder="$t('profilePhonePlaceholder')"
                    type="phone"
                    name="phone"
                />
            </form>
        </div>
        <!-- Informations about account and deleting account -->
        <div class="col-span-12 bg-white mx-30 rounded-lg p-20 shadow">
            <div class="grid grid-cols-12 gap-x-30">
                <Title
                    class="col-span-12 md:col-span-8 lg:col-span-6"
                    :title="$t('profileDataDetailsTitle')"
                    :subtitle="$t('profileDataDetailsSubtitle')"
                />
                <hr class="col-span-12 mb-30">
                <div class="col-span-12 md:col-span-6">
                    <label
                        class="checkbox-container"
                        :for="name"
                    >
                        <span class="text-gray-800 small-bold opacity-85 font-titillium-normal">
                            {{$t('profileTermsAndConditionsText')}}
                        </span>
                        <input
                            id="termsAndConditions"
                            type="checkbox"
                            checked
                            disabled
                            class="checkbox opacity-85"
                        >
                        <span class="checkmark"></span>
                    </label>
                    <p class="pt-12 tracking-tight font-titillium-normal text-gray-800 opacity-85">
                        {{$t('profileNoAgreementText')}}
                        <a
                            :href="`mailto:${emailCode4RO}`"
                            class="text-indigo-400 underline"
                        >
                            {{$t('profileNoAgreementLink')}}
                        </a>
                    </p>
                </div>
                <div class="col-span-12 md:col-span-6 mt-30 md:mt-0 ">
                    <p class="mb-30 tracking-tight text-gray-800 opacity-85 font-titillium-normal">{{$t('profileDeleteHintMessageText')}}</p>
                    <div class="flex justify-start w-257">
                        <Button
                            @btnClick="showDeleteModal = true"
                            class="button w-257 bg-red-500 text-white hover:bg-red-600 cursor-pointer"
                            role="submit"
                            :text="$t('profileDeleteAccountButtonText')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Delete user profile modal -->
        <Modal
            width='w-420'
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @closeOnEscPressed="showDeleteModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl text-gray-800">{{ $t('profileDeleteText') }}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{ $t('profileDeleteQuestionText') }}
            </p>
            <div slot="body">
                <div class="grid grid-cols-2 gap-30">
                    <Button
                        @btnClick="showDeleteModal = false"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-full px-30 mb-30 cursor-pointer"
                        role="button"
                        :text="$t('profileNoText')"
                    />
                    <Button
                        @btnClick="deleteProfile"
                        class="button bg-red-500 hover:bg-red-600 text-white w-full px-30 mb-30 cursor-pointer"
                        role="button"
                        :text="$t('profileYesText')"
                        :disabled="deleteAccountLoading"
                        :spinning="deleteAccountLoading"
                    />
                </div>
            </div>
        </Modal>
        <!-- Modal for reset password -->
        <Modal
            width='auto'
            v-if="showModal"
            @close="showModal = false"
            @closeOnEscPressed="showModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl">{{$t('newPasswordFormTitle')}}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{$t('newPasswordFormSubtitle')}}
            </p>
            <div slot="body">
                <form
                    @submit.prevent="onResetPassword"
                    ref="newPasswordFormProfile"
                >
                <!-- Parola curenta -->
                    <Input
                        :error="errors.current_password"
                        :class="[errors.current_password ? 'mb-4' : 'mb-22']"
                        :label="$t('currentPasswordFieldLabel')"
                        :placeholder="$t('passwordFieldPlaceholder')"
                        type="password"
                        name="current_password"
                    />

                    <!-- Parola noua -->
                    <Input
                        :error="errors.password"
                        :class="[errors.password ? 'mb-4' : 'mb-22']"
                        :label="$t('passwordFieldLabel')"
                        :placeholder="$t('passwordFieldPlaceholder')"
                        type="password"
                        name="password"
                    />
                    <!-- Confirmare parola -->
                    <Input
                        :error="errors.password_confirmation"
                        :class="[errors.password_confirmation ? 'mb-8' : 'mb-37']"
                        :label="$t('passwordConfirmationFieldLabel')"
                        :placeholder="$t('passwordConfiramtionFieldPlaceholder')"
                        type="password"
                        name="password_confirmation"
                    />
                    <!-- Submit btn -->
                    <Button
                        class="button bg-purple-500 text-white focus:bg-purple-600 hover:bg-purple-600 mb-5 font-bold cursor-pointer"
                        role="submit"
                        :text="$t('passwordConfrimationButtonText')"
                        :disabled="resetPasswordLoading"
                        :spinning="resetPasswordLoading"
                    />
                </form>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import Input from '../../components/form/Input'
import Button from '../../components/buttons/Button'
import Title from '../../components/texts/FormTitle'
import Multiselect from 'vue-multiselect'
import Textarea from '../../components/form/Textarea'
import Modal from '@/components/modal/Modal'
import SuccessBox from '@/components/success-message/SuccessBox'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Profile',
    components: {
        Input,
        Title,
        Button,
        Multiselect,
        Textarea,
        Modal
    },
    data () {
        return {
            emailCode4RO: process.env.VUE_APP_CODE4RO_EMAIL,
            logOutState: {},
            showModal: false,
            disabledSaveOrganization: true,
            disabledSaveAboutYou: true,
            intialUserData: {
                name: '',
                email: '',
                phone: '',
                ngo: {
                    description: '',
                    county: {},
                    city: {},
                    address: '',
                    website: '',
                    cif: '',
                    registration_number: '',
                    services: [{ id: null }]
                }
            },
            initialStaffUser: {
                ngo_name: '',
                name: '',
                email: '',
                phone: ''
            },
            name: '',
            showDeleteModal: false,

            logoutLoading: false,
            saveNgoDetailsLoading: false,
            saveProfileDetailsLoading: false,
            resetPasswordLoading: false,
            deleteAccountLoading: false,
            current_password_error: ''
        }
    },
    computed: {
        /** Vuex getters. */
        ...mapGetters(['token', 'userTypeManager', 'user', 'editUserProfileStatus', 'editNgoStatus', 'services', 'counties', 'cities', 'errors', 'error', 'resetPasswordProfileStatus', 'prefix'])
    },
    methods: {
        /** Vuex actions. */
        ...mapActions(['logout', 'fetchProfile', 'fetchCounties', 'fetchCities', 'fetchServices', 'updateUserProfile', 'updateNgo', 'resetPasswordProfile', 'eraseErrors', 'deleteUserProfile']),

        /** If input values changes activate update ngo button. */
        activateSaveNgoButton () {
            if (
                (this.intialUserData.ngo.website !== this.user.ngo.website) ||
                (this.intialUserData.ngo.cif !== this.user.ngo.cif) ||
                (this.intialUserData.ngo.description !== this.user.ngo.description) ||
                (this.intialUserData.ngo.registration_number !== this.user.ngo.registration_number) ||
                (this.intialUserData.ngo.address !== this.user.ngo.address)
            ) {
                this.disabledSaveOrganization = false
            } else {
                this.disabledSaveOrganization = true
            }
        },

        /** If input values changes activate update user button. */
        activateSaveUserProfile () {
            if (this.userTypeManager) {
                if (
                    (this.intialUserData.name !== this.user.name) ||
                    (this.intialUserData.email !== this.user.email) ||
                    (this.intialUserData.phone !== this.user.phone)
                ) {
                    this.disabledSaveAboutYou = false
                } else {
                    this.disabledSaveAboutYou = true
                }
            } else {
                if (
                    (this.initialStaffUser.name !== this.user.name) ||
                    (this.initialStaffUser.email !== this.user.email) ||
                    (this.initialStaffUser.phone !== this.user.phone)
                ) {
                    this.disabledSaveAboutYou = false
                } else {
                    this.disabledSaveAboutYou = true
                }
            }
        },

        countySelected (county) {
            /** Extract the cities of the selected county. */
            if (county) {
                this.fetchCities(county?.id)
            } else {
                this.$store.commit('setCities', [])
            }

            this.user.ngo.city = ''

            /** If county value changes activate save button. */
            if ((this.intialUserData.ngo.county !== this.user.ngo.county)) {
                this.disabledSaveOrganization = false
            } else {
                this.disabledSaveOrganization = true
            }
        },

        citySelected () {
            /** If county value changes activate save button. */
            if ((this.intialUserData.ngo.city !== this.user.ngo.city)) {
                this.disabledSaveOrganization = false
            } else {
                this.disabledSaveOrganization = true
            }
        },

        servicesSelected () {
            /** If county value changes activate save button. */
            if ((this.intialUserData.ngo.services !== this.user.ngo.services) || (this.intialUserData.ngo.services.length !== this.user.ngo.services.length)) {
                this.disabledSaveOrganization = false
            } else {
                this.disabledSaveOrganization = true
            }
        },

        /** Update user. */
        onUserProfileUpdate () {
            this.saveProfileDetailsLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.updateUserProfile(
                {
                    token: this.token,
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.prefix + this.user.phone
                }
            ).then(() => {
                if (this.editUserProfileStatus === true) {
                    this.saveProfileDetailsLoading = false
                    this.disabledSaveAboutYou = true

                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('profileUpdated'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)
                } else {
                    this.saveProfileDetailsLoading = false
                    if (this.error !== '') {
                        this.disabledSaveAboutYou = true

                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
            })
        },

        /** Update ngo. */
        onUserNgoUpdate () {
            this.saveNgoDetailsLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const ngo = {
                name: this.user.ngo.name,
                cif: this.user.ngo.cif,
                address: this.user.ngo.address,
                city_id: this.user.ngo.city?.id,
                county_id: this.user.ngo.county?.id,
                website: this.user.ngo.website,
                description: this.user.ngo.description,
                registration_number: this.user.ngo.registration_number,
                services: this.user.ngo.services.length === 0 ? [{ id: null }] : this.user.ngo.services
            }
            this.updateNgo(
                {
                    token: this.token,
                    ngo
                }
            ).then(() => {
                if (this.editNgoStatus === true) {
                    this.saveNgoDetailsLoading = false
                    this.disabledSaveOrganization = true

                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('profileUpdated'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)
                } else {
                    this.saveNgoDetailsLoading = false
                    if (this.error !== '') {
                        this.disabledSaveOrganization = true

                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
            })
        },

        /** Password reset. */
        onResetPassword () {
            this.resetPasswordLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.resetPasswordProfile({
                token: this.token,
                current_password: this.$refs.newPasswordFormProfile.current_password.value,
                password: this.$refs.newPasswordFormProfile.password.value,
                password_confirmation: this.$refs.newPasswordFormProfile.password_confirmation.value
            })
                .then(response => {
                    if (this.resetPasswordProfileStatus === true) {
                        this.resetPasswordLoading = false
                        this.showModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('profileResetPasswordSuccessMessage'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.resetPasswordLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Show cofirmation modal for deleting profile and delete profile. */
        deleteProfile () {
            this.deleteAccountLoading = true
            this.deleteUserProfile({ token: this.token })
                .then(() => {
                    this.deleteAccountLoading = false
                })
        },

        /** Logout */
        onLogout () {
            this.logoutLoading = true

            this.logout({ token: this.token }).then(() => {
                this.logoutLoading = false
                this.$store.replaceState(this.logOutState)
                this.intialUserData = {}
                this.initialStaffUser = {}
                this.$router.go(0)
            })
        },

        userPhone () {
            this.user.phone = this.user.phone.slice(3)
            if (this.userTypeManager) {
                this.intialUserData.phone = this.user.phone
            } else {
                this.initialStaffUser.phone = this.user.phone
            }
        }
    },

    async created () {
        /** Extract all the counties. */
        await this.fetchCounties()

        /** Extract all the services. */
        await this.fetchServices()

        /** Extract user information. */
        await this.fetchProfile({ token: this.token })
            .then(response => {
                this.userPhone()
                /** Initial user state. */
                if (this.userTypeManager) {
                    this.intialUserData = JSON.parse(JSON.stringify(this.user))
                    this.fetchCities(this.user.ngo.county.id)
                } else {
                    this.initialStaffUser = JSON.parse(JSON.stringify(this.user))
                }
            })
            .catch(error => {
                /** If error log the error. */
                console.log(error.response)
            })
    }
}
</script>
